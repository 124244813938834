import { styled } from 'Theme/stitches.config';
import { useCallback, useEffect, useRef, useState } from 'react';
import BodyText from 'DesignSystem/Typography/BodyText/BodyText';
import { Chevron, Filter } from 'DesignSystem/Icons';
import RadioButton from 'DesignComponents/Atoms/OLD/RadioButton/RadioButton';
import SorterModel from 'Models/Search/SorterModel.interface';
import { useFilterData } from 'context/filter.context';
import { useUiState } from 'Shared/Providers/UiState/UiStateProvider';
import { mapModalView } from 'DesignComponents/Organisms/Modal/ModalContentViews';
import FilterModalFooter from 'Commerce/Organisms/FilterComponent/FilterModalFooter';
import { mapModalFooterView } from 'DesignComponents/Organisms/Modal/ModalFooterViews';
import { ModalFooterLayoutOption } from 'Enums/ModalFooterLayout.enum';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import SearchFilterFacetsModalContainer from '../SearchFilterFacets/SearchFilterFacetsModalContainer';
import ItemsNotifier from 'DesignComponents/Atoms/ItemsNotifier/ItemsNotifier';
import KexFacet from 'Models/Search/KexFacet.interface';

import { useTranslations } from '../../../../context/init-data.context';

type PropTypes = {
  sorters?: SorterModel[];
  searchHits: number;
  searchHitsText?: string;
  categoryCode?: string;
  facets: KexFacet[];
};

function SearchOrderSort({
  sorters,
  searchHitsText,
  categoryCode,
  facets,
}: PropTypes) {
  const {
    searchLabels: {
      sortOrder: sortOrderText,
      sortOrderOn: sortOrderOnText,
      filters: filtersText,
    },
  } = useTranslations();
  const { modalState, toggleModal } = useUiState();
  const [filterData, dispatchFilterData] = useFilterData();
  const [focusedItem, setFocusedItem] = useState<number>(1000);
  const isMobile = useMedia(mediaQueryTypes.bpMax720);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleButtonRef = useRef<HTMLButtonElement>(null);
  const [openSortDropdown, setOpenSortDropdown] = useState<boolean>(false);

  const sortDropdownToggle = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    setOpenSortDropdown(!openSortDropdown);
  };

  const sortOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.type === 'radio') {
        const selectedSorter = sorters?.find(
          ({ value }) => value === Number(event.target.value)
        );

        if (selectedSorter) {
          dispatchFilterData({
            type: 'setSorterFilter',
            value: selectedSorter,
          });
        }
        setOpenSortDropdown(false);
      }
    },
    [dispatchFilterData]
  );

  const handleOutsideClick = (e: MouseEvent | TouchEvent) => {
    if (
      toggleButtonRef.current &&
      !toggleButtonRef.current.contains(e.target as Node) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      openSortDropdown && setOpenSortDropdown(false);
    }
  };

  const countSelectedFilters = useCallback(() => {
    let totalItems = 0;

    const facetGroupsArray = Array.from(
      filterData.multiSelectFilters.entries()
    );

    //loop through facet groups
    for (const [key, facets] of facetGroupsArray) {
      const facetArray = Array.from(facets);
      if (facets instanceof Set) {
        for (const facet of facetArray) {
          if (isFacetAvailable(key, facet)) {
            totalItems++;
          }
        }
      }
    }

    return totalItems;
  }, [filterData.multiSelectFilters, facets]);

  const isFacetAvailable = useCallback(
    (key: string, item: string) => {
      const facet = facets.find((facet) => facet.name === key);

      //loop through terms
      if (facet) {
        for (const term of facet.terms) {
          if (term.term === item) {
            return true;
          }
        }
      }

      return false;
    },
    [facets]
  );

  const filterModalToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    toggleModal(
      !modalState.display,
      'Filter',
      filtersText,
      {
        component: mapModalView<typeof SearchFilterFacetsModalContainer>({
          type: 'Filter',
          props: { categoryCode: categoryCode },
        }),
        noContentPadding: true,
      },
      mapModalFooterView<typeof FilterModalFooter>({
        type: 'FilterModalFooter',
        props: {
          modalFooterContent: {
            footerLayout: ModalFooterLayoutOption.SIDE_BY_SIDE,
          },
          categoryCode: categoryCode,
        },
      }),
      e.currentTarget as HTMLElement
    );
  };

  useEffect(() => {
    if (!openSortDropdown) setFocusedItem(1000);
  }, [openSortDropdown]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  });

  return (
    <SortWrapper>
      <SortLeft aria-live="polite" aria-atomic="true">
        {searchHitsText}
      </SortLeft>

      <SortRight>
        {isMobile && (
          <ToggleDropdownButton
            onClick={filterModalToggle}
            style={{ cursor: 'pointer' }}
            aria-controls="filterDiv"
          >
            <BodyText key={'filter'} css={filterAndOrderStyle}>
              {filtersText}
              <IconWrapper>
                <Filter size="m" color="primary" />
                {filterData.multiSelectFilters.size > 0 && (
                  <ItemsNotifier items={countSelectedFilters()} />
                )}
              </IconWrapper>
            </BodyText>
          </ToggleDropdownButton>
        )}
        {sorters && (
          <ToggleDropdownButton
            ref={toggleButtonRef}
            onClick={(e) => sortDropdownToggle(e)}
            aria-expanded={openSortDropdown}
            aria-controls="SortDropdown"
            aria-haspopup="listbox"
            role="combobox"
            onKeyDown={(e) => {
              if (!openSortDropdown) {
                if (
                  e.key === 'ArrowDown' ||
                  e.key === 'ArrowUp' ||
                  e.key === ' ' ||
                  e.key === 'Enter'
                ) {
                  e.preventDefault();

                  setOpenSortDropdown(true);
                }
              } else {
                if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                  e.preventDefault();

                  setFocusedItem(0);
                }
              }
            }}
          >
            {isMobile
              ? sortOrderText
              : `${sortOrderOnText} ${filterData.sorterFilter.text}`}
            <Chevron size="m" color="primary" />
          </ToggleDropdownButton>
        )}

        {sorters && openSortDropdown && (
          <SortDropdown
            ref={dropdownRef}
            id="SortDropdown"
            role="listbox"
            onKeyDown={(e) => {
              switch (e.key) {
                case 'ArrowDown':
                  e.preventDefault();
                  if (focusedItem < sorters.length - 1) {
                    setFocusedItem(focusedItem + 1);
                  }
                  break;

                case 'ArrowUp':
                  e.preventDefault();
                  if (focusedItem > 0) {
                    setFocusedItem(focusedItem - 1);
                  }
                  break;

                case 'Escape':
                case 'Tab':
                  setOpenSortDropdown(false);
                  break;

                default:
                  break;
              }
            }}
          >
            {sorters.map((item, index) => (
              <RadioButton
                onChange={(e) => sortOnChange(e)}
                isChecked={item.value === filterData.sorterFilter.value}
                text={item.text}
                key={item.value}
                value={item.value}
                groupName={'sort_search'}
                focused={index === focusedItem}
              />
            ))}
          </SortDropdown>
        )}
      </SortRight>
    </SortWrapper>
  );
}

export default SearchOrderSort;

const SortWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  mb: 8,
  '@bpMin721': {
    mb: 10,
  },
  '& label': {
    marginBottom: '$s50',
  },
});

const SortLeft = styled('div', {
  fontSize: '$fontSize75',
  '@bpMin721': {
    fontSize: '$fontSize100',
  },
});
const SortRight = styled('div', {
  display: 'flex',
  justifyContent: 'end',
  position: 'relative',
});

const ToggleDropdownButton = styled('button', {
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
  fontSize: '$fontSize75',
  '@bpMin721': {
    fontSize: '$fontSize100',
  },
  ml: 3,
});

const SortDropdown = styled('div', {
  position: 'absolute',
  t: 8,
  p: '16px 48px 24px 24px',
  zIndex: '$DropDown',
  background: '$white',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '$interactiveBorderSeparator',
});

const filterAndOrderStyle = {
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
  fontSize: '$fontSize75',
  '@bpMin721': {
    fontSize: '$fontSize100',
  },
};

const IconWrapper = styled('span', {
  position: 'relative',
  wh: 24,
});
